import {BlinkPayConfig, BlinkDebitClient} from 'blink-debit-api-client-node';
import globalAxios from './axiosInstance';

const blinkPayConfig: BlinkPayConfig = {
  blinkpay: {
    debitUrl: process.env.REACT_APP_BLINKPAY_DEBIT_URL || '',
    clientId: process.env.REACT_APP_BLINKPAY_CLIENT_ID || '',
    clientSecret: process.env.REACT_APP_BLINKPAY_CLIENT_SECRET || '',
    timeout: 10000,
    retryEnabled: true
  }
};

export const client: BlinkDebitClient = new BlinkDebitClient(globalAxios, blinkPayConfig);