import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import App from './App';

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
        <React.StrictMode>
            <App/>
        </React.StrictMode>
);
