import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import logo from './acme.png';
import './App.css';
import Cart from './components/cart';
import Redirect from './components/redirect';
import Pay from './components/pay';

class App extends Component<any, any> {
    render() {
        return (
                <div className="App">
                    <header className="App-header">
                        <img src={logo as string} className="App-logo" alt="logo"/>
                    </header>
                    <main className="App-content">
                        <Router>
                            <Routes>
                                <Route path="/" element={<Cart/>}/>
                                <Route path="/redirect" element={<Redirect/>}/>
                                <Route path="/pay/*" element={<Pay/>}/>
                            </Routes>
                        </Router>
                    </main>
                </div>
        );
    }
}

export default App;
