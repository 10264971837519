import React, {Component} from 'react';
import lollipop from '../lolly.webp';
import {
    Amount,
    AmountCurrencyEnum,
    AuthFlow,
    AuthFlowDetailTypeEnum,
    ConsentDetailTypeEnum,
    CreateConsentResponse,
    CreateQuickPaymentResponse,
    EnduringConsentRequest,
    GatewayFlow,
    Pcr,
    Period,
    QuickPaymentRequest,
    SingleConsentRequest
} from 'blink-debit-api-client-node';
import {client} from '../blinkDebitClientInstance';
import {AppRedirectFlow} from "../AppRedirectFlow";

interface State {
    errorResponse: any,
    disabled: boolean,
    clickedButton?: string,
    imageLoaded: boolean,
    showPopup: boolean
}

class Cart extends Component<{}, State> {

    constructor(props: {}) {
        super(props);

        this.state = {
            errorResponse: {},
            disabled: false,
            imageLoaded: false,
            showPopup: false
        };

        this.submitForm = this.submitForm.bind(this);
    }

    handleButtonClick = (value: string, e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        if (process.env.REACT_APP_ENV === 'prod') {
            this.setState({showPopup: true, clickedButton: value});
            return;
        }

        this.setState({disabled: true, clickedButton: value}, () => {
            this.submitForm(new Event('submit') as unknown as React.FormEvent);
        });
    };

    handlePopupConfirm = (): void => {
        this.setState({showPopup: false, disabled: true}, () => {
            this.submitForm(new Event('submit') as unknown as React.FormEvent);
        });
    };

    handlePopupCancel = (): void => {
        this.setState({showPopup: false, disabled: false, clickedButton: undefined});
    };

    handleImageLoad = (): void => {
        this.setState({imageLoaded: true});
    };

    async submitForm(e: React.FormEvent): Promise<void> {
        e.preventDefault();

        const buttonValue: string | undefined = this.state.clickedButton;

        this.setState({disabled: true, errorResponse: {}});

        if (buttonValue === "single") {
            const request: QuickPaymentRequest = {
                type: ConsentDetailTypeEnum.Single,
                flow: {
                    detail: {
                        type: AuthFlowDetailTypeEnum.Gateway,
                        redirectUri: `${window.location.origin}/redirect`
                    } as GatewayFlow
                } as AuthFlow,
                amount: {
                    currency: AmountCurrencyEnum.NZD,
                    total: process.env.REACT_APP_BLINKPAY_AMOUNT
                } as Amount,
                pcr: {
                    particulars: "lollipop",
                    code: "code",
                    reference: "reference"
                } as Pcr
            };

            const createQuickPaymentResponse: CreateQuickPaymentResponse = await client.createQuickPayment(request);
            const redirectUri: string | undefined = createQuickPaymentResponse.redirectUri;
            if (redirectUri !== undefined) {
                window.location.assign(redirectUri);
            }
        } else if (buttonValue === "asb") {
            const request: QuickPaymentRequest = {
                type: ConsentDetailTypeEnum.Single,
                flow: {
                    detail: {
                        bank: 'ASB',
                        type: AuthFlowDetailTypeEnum.Redirect,
                        redirectUri: 'blinkpay://test-app/return',
                        redirectToApp: true
                    } as AppRedirectFlow
                } as AuthFlow,
                amount: {
                    currency: AmountCurrencyEnum.NZD,
                    total: process.env.REACT_APP_BLINKPAY_AMOUNT
                } as Amount,
                pcr: {
                    particulars: "lollipop",
                    code: "code",
                    reference: "reference"
                } as Pcr
            };

            const createQuickPaymentResponse: CreateQuickPaymentResponse = await client.createQuickPayment(request);
            const redirectUri: string | undefined = createQuickPaymentResponse.redirectUri;
            if (redirectUri !== undefined) {
                window.location.assign(redirectUri);
            }
        } else if (buttonValue === "enduring") {
            const request: EnduringConsentRequest = {
                type: ConsentDetailTypeEnum.Enduring,
                flow: {
                    detail: {
                        type: AuthFlowDetailTypeEnum.Gateway,
                        redirectUri: `${window.location.origin}/pay`
                    } as GatewayFlow
                } as AuthFlow,
                maximumAmountPeriod: {
                    currency: AmountCurrencyEnum.NZD,
                    total: process.env.REACT_APP_BLINKPAY_AMOUNT
                } as Amount,
                fromTimestamp: new Date(),
                period: Period.Fortnightly
            };

            const createConsentResponse: CreateConsentResponse = await client.createEnduringConsent(request);
            const redirectUri: string | undefined = createConsentResponse.redirectUri;
            if (redirectUri !== undefined) {
                window.location.assign(redirectUri);
            }
        } else if (buttonValue === "xero") {
            const request: SingleConsentRequest = {
                type: ConsentDetailTypeEnum.Single,
                flow: {
                    detail: {
                        type: AuthFlowDetailTypeEnum.Gateway,
                        redirectUri: `${window.location.origin}/redirect`
                    } as GatewayFlow
                } as AuthFlow,
                amount: {
                    currency: AmountCurrencyEnum.NZD,
                    total: process.env.REACT_APP_BLINKPAY_AMOUNT
                } as Amount,
                pcr: {
                    particulars: "lollipop",
                    code: "code",
                    reference: "reference"
                } as Pcr
            };

            const createConsentResponse: CreateConsentResponse = await client.createSingleConsent(request);
            const redirectUri: string | undefined = createConsentResponse.redirectUri;
            if (redirectUri !== undefined) {
                window.location.assign(redirectUri + "&source=xero");
            }
        }
    }

    render() {
        const {imageLoaded} = this.state;

        return (
                <div className="container">
                    <h3 className="text-center">Shopping Cart</h3>
                    <form onSubmit={this.submitForm} noValidate>
                        <div className="row align-items-center justify-content-center product-card">
                            <div className="col-md-4 col-sm-12 mb-3">
                                {!imageLoaded && (
                                        <div className="d-flex justify-content-center align-items-center"
                                             style={{height: 200}}>
                                            <div className="spinner-border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                )}
                                <img
                                        src={lollipop as string}
                                        alt="lollipop"
                                        className={`img-fluid ${imageLoaded ? '' : 'd-none'}`}
                                        onLoad={this.handleImageLoad}
                                />
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <p className="mb-1">Red Heart Lollipop, unwrapped</p>
                                <p className="mb-1">Price: ${process.env.REACT_APP_BLINKPAY_AMOUNT}</p>
                            </div>
                        </div>

                        <div className="row align-items-center justify-content-center mt-4">
                            <div className="col-12 text-center">
                                <button
                                        type="submit"
                                        className="btn btn-primary m-2"
                                        onClick={(e) => this.handleButtonClick('single', e)}
                                        disabled={this.state.disabled}
                                >
                                    PayNow
                                </button>
                                <button
                                        type="submit"
                                        className="btn btn-primary m-2"
                                        onClick={(e) => this.handleButtonClick('enduring', e)}
                                        disabled={this.state.disabled}
                                >
                                    AutoPay
                                </button>
                                <button
                                        type="submit"
                                        className="btn btn-primary m-2"
                                        onClick={(e) => this.handleButtonClick('xero', e)}
                                        disabled={this.state.disabled}
                                >
                                    Invoice
                                </button>
                            </div>
                        </div>
                    </form>

                    {/* Popup */}
                    {this.state.showPopup && (
                            <div className="popup-overlay">
                                <div className="popup">
                                    <button className="popup-close" onClick={this.handlePopupCancel}>
                                        &times;
                                    </button>
                                    <h4>Confirm Payment</h4>
                                    <p>
                                        If accepted, this will constitute a real charge on your bank account.
                                    </p>
                                    <div className="popup-actions">
                                        <button
                                                className="btn btn-success"
                                                onClick={this.handlePopupConfirm}
                                        >
                                            Confirm
                                        </button>
                                        <button
                                                className="btn btn-danger"
                                                onClick={this.handlePopupCancel}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                    )}
                </div>
        );
    }
}

export default Cart;
